<template>
  <div class="search">
    <div class="container">
      <div class="bread">
        <route-link to="/">Главная/</route-link>
        <router-link to="">Результаты поискrа</router-link>
      </div>
      <div class="amount">
        <p>Найдено {{ result.length }}</p>
      </div>
      <div
        class="search__content"
        v-for="name in result"
        :key="name.name + name.href + name.title"
      >
        <locale-router-link
          class="search__content__routes"
          :to="name.href"
          v-text="name.title"
        ></locale-router-link>
        <p class="search__content__text" v-text="name.name"></p>
      </div>
    </div>
  </div>
</template>

<script>
// import loadLocaleMessages from "../plugins/i18n";
import ru from "../locales/ru.json";
import kz from "../locales/kz.json";
export default {
  name: "Search",
  data() {
    return {
      result: [],
    };
  },
  mounted() {
    // console.log("LOCALES MSG", loadLocaleMessages());
    this.search(this.$store.state.searchItem);
  },
  watch: {
    "$store.state.searchItem"() {
      this.search(this.$store.state.searchItem);
    },
  },
  methods: {
    search(val) {
      console.log("val", val);
      // let search = val[outerIndex];
      // инструменты

      // console.log(ru, kz);

      // найти keys в файлах ru.json, kz.json
      let keys = [];

      function lookArray(arr, key) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i]) {
            if (Array.isArray(arr[i])) {
              lookArray(arr[i]);
            } else if (typeof arr[i] == "object") {
              lookObject(arr[i], key);
            } else if (typeof arr[i] == "string" && arr[i].includes(val)) {
              arr[i] = arr[i].replace("http", "https");
            }
          }
        }
      }
      function lookObject(obj, primary_key) {
        for (const [key, value] of Object.entries(obj)) {
          if (value) {
            if (Array.isArray(value)) {
              lookArray(obj[key], key);
            } else if (typeof value == "object") {
              lookObject(obj[key]);
            } else if (typeof value == "string" && value.includes(val)) {
              // obj[key] = value.replace("http", "https");
              if (primary_key)
                keys.push({
                  key: primary_key,
                  value,
                });
              else
                keys.push({
                  key,
                  value,
                });
            }
          }
        }
      }
      lookObject(ru);
      lookObject(kz);
      console.log("lol", keys);
      // support
      let paths = {
        about: {
          title: "О нас",
          list: ["about"],
        },
        advertisers: {
          title: "Рекламодателям",
          list: [
            "advert",
            "advertContent",
            "factsTitle",
            "facts",
            "advertEffective",
            "advertItems",
            "advertPeopleTitle",
            "advertPeoples",
            "reviewsTitle",
          ],
        },
        bidding: {
          title: "Торги в Magnum",
          list: [
            "deals",
            "webPortal",
            "dealsTitle",
            "dealsContent",
            "dealsCard1",
            "dealsCard2",
            "dealsLink1",
            "dealsLink2",
            "webPortal",
            "dealsInfo",
          ],
        },
        "compliance-service": {
          title: "Служба Комплаенс",
          list: [
            "compliance",
            "complianceContent",
            "complianceAppeal",
            "complianceContent1",
            "complianceContent2",
            "complianceContent3",
            "complianceName",
            "compliancePosition",
            "complianceTitle1",
            "complianceTitle2",
            "complianceTitle3",
            "complianceTitle4",
            "complianceText1",
            "complianceText2",
            "complianceText3",
            "complianceText4",
            "complianceSubtitle",
            "complianceContact",
            "complianceEmail",
          ],
        },
        "": {
          title: "Главная страница Magnum Corp",
          list: [
            "title",
            "successFormula",
            "titleSuccess1",
            "titleSuccess2",
            "titleSuccess3",
            "contentSuccess1",
            "contentSuccess2",
            "contentSuccess3",
            "advantagesTitle",
            "advantagesContent",
            "advantagesQuote",
            "advantagesAuthor",
            "statistics",
            "formatTitle",
            "defaultTitle",
            "superTitle",
            "expressTitle",
            "dailyTitle",
            "defaultContent",
            "superContent",
            "expressContent",
            "dailyContent",
            "lineText",
          ],
        },
        landLords: {
          title: "Арендодателям",
          list: [
            "landlord",
            "landlordText",
            "landlordTitle",
            "landlordContent",
            "landlordCards",
            "realEstateOffers",
            "landlordFormText",
            "landlordFormText1",
            "landlordOnCallEmail",
            "reviewsTitle",
          ],
        },
        "magnum-opt": {
          title: "Magnum opt",
          list: [
            "opt",
            "optText1",
            "optText2",
            "optText3",
            "optText4",
            "optText5",
            "optText6",
            "optText7",
            "optPayment",
            "optPaymentContent1",
            "optPaymentContent2",
            "bonus",
            "bonusContent",
            "bonuses",
            "optBonuses_1",
            "optBonuses_2",
            "optPartner",
            "optPartners",
            "optFilial",
            "optDocuments",
            "optContacts",
            "optContactsTitle",
            "reviewsTitle",
          ],
        },
        suppliers: {
          title: "Поставщикам",
          list: [
            "vendorTitle",
            "vendorContent",
            "vendorPortal",
            "coopTitle",
            "coopText1",
            "coopText2",
            "coopText3",
            "respectTitle",
            "respectContent",
            "documentsTitle",
            "documentsText1",
            "documentsText2",
            "documentsText3",
            "documentsText4",
            "altDocumentsTitle",
            "reviewsTitle",
            "vendorBecome",
            "feedback",
          ],
        },
        tenants: {
          title: "Арендаторам",
          list: [
            "rentTitle",
            "rentContent",
            "fillApplication",
            "filial",
            "contactsTitle",
            "contactsContent",
            "rentor",
            "reviewsTitle",
          ],
        },
      };
      let result = [];
      for (const [key, value] of Object.entries(paths)) {
        keys.forEach((item) => {
          if (value.list.includes(item.key)) {
            result.push({
              title: value.title,
              name: item.value,
              href: `/${key}`,
            });
          }
        });
      }
      this.result = [...result];
      // for (var index = 0; index < loadLocaleMessages.length; index++) {
      //   if (loadLocaleMessages[index].includes(val[outerIndex])) {
      //     find.push(keywords[outerIndex]);
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 50px;
  .bread {
    color: #999999;
  }
  .amount {
    font-size: 18px;
    color: #2d2f32;
    margin-top: 30px;
  }
  .search__content {
    margin-top: 30px;
    .search__content__routes {
      color: #f50f64;
      margin-bottom: 20px;
    }
    .search__content__text {
      color: #2d2f32;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
</style>
